/* eslint-disable */
// @ts-ignore

import {transformNumber} from "@/helper/functions";
import { MACHINE_FAHRBAR } from '@/constant/constants';

function calculatePramienSelbstbehaltsvariantenItems(pramienSelbstbehaltsvariantenItems: any) {
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.tarif);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante1);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante2);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante3);
}

function calculatePramieergebnisseTarifkalkulationItem(item: any) {
  const laufzeitCoeficient = (item.laufzeit === 3) ? 0.9 : 1;
  let result = item.tarifPramie;
  result *= laufzeitCoeficient;
  result *= (1 - Number(item.SVR) / 100);
  result *= (1 - Number(item.nettoisierung) / 100);
  result *= (1 - Number(item.kurzfristige) / 100);
  // eslint-disable-next-line no-param-reassign
  item.pramieInklTariflicherNachlasse = transformNumber(result);
}
function calculatePramieergebnisseTarifkalkulationDiscounts(PremiumIncludingTariffDiscounts: number, PramieergebnisseTarifkalkulationItem: any) {
  const laufzeitCoeficient = (PramieergebnisseTarifkalkulationItem.laufzeit === 3) ? 0.9 : 1;
  let result = PremiumIncludingTariffDiscounts;
  result *= laufzeitCoeficient;
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.SVR) / 100);
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.nettoisierung) / 100);
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.kurzfristige) / 100);
  // eslint-disable-next-line no-param-reassign
  return transformNumber(result);
}
function getDiscountsBonuses(skillDiscounts) {
  const resultData: Array<number> = [];
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountTariffVariant),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant1),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant2),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant3),
  );
  return resultData;
}
function getDiscountsBonuse(discountsBonusesItem: any): number {
  let result = 1;
  result *= (1 - Number(discountsBonusesItem.estates)/100);
  result *= (1 + Number(discountsBonusesItem.surcharges)/100);
  return result;
}

function calculatepromotionalRewards(promotionalRewards: any, skillDiscounts: any, zusatzlicherVorschadenfaktorСoefficient: number, versicherungssummeAktuell: number) {

  const discountsBonuses = getDiscountsBonuses(skillDiscounts);
  const risikofactor = Number(skillDiscounts.resultRiskFactors);

  promotionalRewards.forEach((item: any, index) => {
    calculatepromotionalRewardItem(item, risikofactor, discountsBonuses[index], zusatzlicherVorschadenfaktorСoefficient, versicherungssummeAktuell)
  });
}
function calculatepromotionalRewardItem(pramienSelbstbehaltsvariantenItem: any, risikofactor: number, discountsBonus: number, zusatzlicherVorschadenfaktorСoefficient: number, versicherungssummeAktuell: number) {
  pramienSelbstbehaltsvariantenItem.PremiumIncludingRiskAdjustmentFactor = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingTariffDiscounts * risikofactor);
  pramienSelbstbehaltsvariantenItem.PremiumIncludingMarketFactor = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingRiskAdjustmentFactor * discountsBonus);
  pramienSelbstbehaltsvariantenItem.NetOfferPremium = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingMarketFactor * zusatzlicherVorschadenfaktorСoefficient);
  if (versicherungssummeAktuell > 0) {
    pramienSelbstbehaltsvariantenItem.NetOfferPremiumRate = transformNumber((pramienSelbstbehaltsvariantenItem.NetOfferPremium / versicherungssummeAktuell) * 1000);
  }
  pramienSelbstbehaltsvariantenItem.GrossOfferPremium = pramienSelbstbehaltsvariantenItem.NetOfferPremium * 1.19;
}
function getVersicherungssummeAktuell(positionsdatenItems: any) {
  let result = 0;
  positionsdatenItems.forEach((item: any, index) => {
    result += item.Versicherunswert;
  });
  return result;
}
function fillErgebnisseTarifkalkulationPramie(priceResponseData, spreadsheetData, basicData, priceData) {
  const positionsdatenItems = spreadsheetData.positionsdatenItems;
  const vertragsbezogeneZuschlageItems = spreadsheetData.vertragsbezogeneZuschlageItems;
  const pramienSelbstbehaltsvariantenItems = spreadsheetData.pramienSelbstbehaltsvariantenItems;
  const pramienSelbstbehaltsvariantenItem = spreadsheetData.pramienSelbstbehaltsvariantenItem;

  const selbstbehaltValues = getSelbstbehaltValues(
    pramienSelbstbehaltsvariantenItems,
    positionsdatenItems,
    pramienSelbstbehaltsvariantenItem
  );

  setSelbstbehaltValues(priceData.pramienSelbstbehaltsvariantenItems, selbstbehaltValues);

  ['tarif', 'variant1', 'variant2', 'variant3'].forEach((variant, index) => {
    if (checkIfPriceDataHaveResponse(priceResponseData, variant)) {
      console.log(variant);
      const PositionsdatenSumm = getPramiePositionsdatenSumm(
        priceResponseData[variant].priceResponse.Result.Maschinen,
        positionsdatenItems,
        basicData.vertragsart,
        vertragsbezogeneZuschlageItems
      );
      const PramieERSBruttoFactor = Number(priceResponseData[variant].priceResponse.Result['Prämienübersicht']['Prämie_ERS_Brutto']);

      setPremiumValues(priceData, PositionsdatenSumm, PramieERSBruttoFactor, index);
      priceData.pramienSelbstbehaltsvariantenItems[getVariantKey(index)].tarifPramie = PositionsdatenSumm + PramieERSBruttoFactor;
    }
  });

  const zusatzlicherVorschadenfaktorCoefficient = (100 - priceData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;

  calculatePromotionalRewards(priceData, zusatzlicherVorschadenfaktorCoefficient);
  calculatePramienSelbstbehaltsvariantenItems(priceData.pramienSelbstbehaltsvariantenItems);
}

function getSelbstbehaltValues(pramienSelbstbehaltsvariantenItems, positionsdatenItems, sbTariffItem) {
  let data = pramienSelbstbehaltsvariantenItems;

  if (pramienSelbstbehaltsvariantenItems[0] && sbTariffItem) {
    data = {
      tarif: sbTariffItem,
      variant1: pramienSelbstbehaltsvariantenItems[0],
      variant2: pramienSelbstbehaltsvariantenItems[1],
    };
  }

  return {
    tarif: data.tarif.SB,
    variant1: data.variant1.SB,
    variant2: data.variant2.SB,
    variant3: getSBPositionsdatenSumm(positionsdatenItems)
  };
}

function setSelbstbehaltValues(pramienSelbstbehaltsvariantenItems, values) {
  pramienSelbstbehaltsvariantenItems.tarif.selbstbehalt = values.tarif;
  pramienSelbstbehaltsvariantenItems.SBVariante1.selbstbehalt = values.variant1;
  pramienSelbstbehaltsvariantenItems.SBVariante2.selbstbehalt = values.variant2;
  pramienSelbstbehaltsvariantenItems.SBVariante3.selbstbehalt = values.variant3;
}

function setPremiumValues(priceData, PositionsdatenSumm, PramieERSBruttoFactor, index) {
  const variantKey = getVariantKey(index);
  priceData.maschinenItems[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;
  priceData.erstrisikosummenItems[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;
  priceData.promotionalRewards[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;

  priceData.maschinenItems[index].PremiumIncludingTariffDiscounts = calculatePramieergebnisseTarifkalkulationDiscounts(PositionsdatenSumm, priceData.pramienSelbstbehaltsvariantenItems[variantKey]);
  priceData.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts = calculatePramieergebnisseTarifkalkulationDiscounts(PramieERSBruttoFactor, priceData.pramienSelbstbehaltsvariantenItems[variantKey]);
  priceData.promotionalRewards[index].PremiumIncludingTariffDiscounts = priceData.maschinenItems[index].PremiumIncludingTariffDiscounts + priceData.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts;
}

function getVariantKey(index) {
  const keys = ['tarif', 'SBVariante1', 'SBVariante2', 'SBVariante3'];
  return keys[index];
}

function calculatePromotionalRewards(priceData, coefficient) {
  const versicherungssummeAktuell = priceData.zusammenfassung.versicherungssummeAktuell;
  calculatepromotionalRewards(priceData.maschinenItems, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
  calculatepromotionalRewards(priceData.erstrisikosummenItems, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
  calculatepromotionalRewards(priceData.promotionalRewards, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
}

function getSBPositionsdatenSumm(positionsdatenItems) {
  let sum = 0;

  positionsdatenItems.forEach((item: any, index) => {
    sum += Number(item.SB);
  });

  return sum;
}

function getPramiePositionsdatenSumm(Maschinen, positionsdatenItems, vertragsart, vertragsbezogeneZuschlageItems) {
  let summ = 0;

  positionsdatenItems.forEach((item: any, index) => {
    console.log(`positionsdaten item ${index + 1}` );
    let result = getPramiePositionsdatenItem(Maschinen[index]['Prämie_SB_Var1_Maschine'], item, vertragsart, vertragsbezogeneZuschlageItems);
    summ += result;
  });
  console.log(`positionsdaten items summa: ${summ}` );
  return summ
}

function getPramiePositionsdatenItem(pramie, positionsdatenItem, vertragsart, vertragsbezogeneZuschlageItems) {
  const spindelklauselFactor = getSpindelklauselFactor(positionsdatenItem.Spindelklausel);
  const vermietungUndVerpachtungFactor = getVermietungUndVerpachtungFactor(positionsdatenItem.VermietungUndVerpachtung);
  const vertragsartFactor = getVertragsartFactor(vertragsart, positionsdatenItem.GAPDeckung);
  const unterschlagungFOTGerateFactor = getUnterschlagungFOTGerateFactor(positionsdatenItem.UnterschlagungFOTGerate);
  const mitversicherungInnereUnruhenFactor = getMitversicherungInnereUnruhen(vertragsbezogeneZuschlageItems[0].MitversicherungInnereUnruhen);

  let logMessage = `Initial value (Prämie_SB_Var1_Maschine):  ${pramie}\n`;

  let result = Number(pramie) * (1 - Number(positionsdatenItem.Neuheitsrabatt) / 100);

  logMessage += `Neuheitsrabatt (${positionsdatenItem.Neuheitsrabatt}%): ${result}\n`;

  result *= Number(spindelklauselFactor);
  logMessage += `Spindelklausel factor (${spindelklauselFactor}): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.StationareMaschinenMobilEingesetztPercent) / 100);
  logMessage += `Stationäre Maschinen mobil eingesetzt (${positionsdatenItem.StationareMaschinenMobilEingesetztPercent}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.MitversicherungFeuerInMASS) / 100);
  logMessage += `Mitversicherung Feuer in MASS (${positionsdatenItem.MitversicherungFeuerInMASS}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.MitversicherungUberschwemmungInMASS) / 100);
  logMessage += `Mitversicherung Überschwemmung in MASS (${positionsdatenItem.MitversicherungUberschwemmungInMASS}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.MitversicherungErdbebenInMASS) / 100);
  logMessage += `Mitversicherung Erdbeben in MASS (${positionsdatenItem.MitversicherungErdbebenInMASS}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.EinsatzImAusland) / 100);
  logMessage += `Einsatz im Ausland (${positionsdatenItem.EinsatzImAusland}%): ${result}\n`;

  result *= (1 - Number(positionsdatenItem.individualDiscount) / 100);
  logMessage += `Individueller Nachlass (${positionsdatenItem.individualDiscount}%): ${result}\n`;

  if (positionsdatenItem.stillstandsrabatt) {
    result *= (1 - Number(positionsdatenItem.stillstandsrabatt) / 100);
    logMessage += `Stillstandsrabatt (${positionsdatenItem.stillstandsrabatt}%): ${result}\n`;
  }

  result *= (1 + Number(positionsdatenItem.individualSurcharge) / 100);
  logMessage += `Individueller Zuschlag (${positionsdatenItem.individualSurcharge}%): ${result}\n`;

  result *= (1 - Number(positionsdatenItem.AusschlussKraftKaskoRisiko) / 100);
  logMessage += `Ausschluss Kraft-Kasko-Risiko (${positionsdatenItem.AusschlussKraftKaskoRisiko}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.SchadenDurchVuV) / 100);
  logMessage += `Schäden durch VuV (${positionsdatenItem.SchadenDurchVuV}%): ${result}\n`;

  result *= (1 + Number(positionsdatenItem.EinsatzUnterTage) / 100);
  logMessage += `Einsatz unter Tage (${positionsdatenItem.EinsatzUnterTage}%): ${result}\n`;

  result *= (1 - Number(positionsdatenItem.LandwirtschaftlicherStillstandsrabatt ?? 0) / 100);
  logMessage += `Landwirtschaftlicher Stillstandsrabatt (${positionsdatenItem.LandwirtschaftlicherStillstandsrabatt ?? 0}%): ${result}\n`;

  result *= Number(vermietungUndVerpachtungFactor);
  logMessage += `Vermietung und Verpachtung factor (${vermietungUndVerpachtungFactor}): ${result}\n`;

  result *= Number(vertragsartFactor);
  logMessage += `Vertragsart factor (${vertragsartFactor}): ${result}\n`;

  result *= Number(unterschlagungFOTGerateFactor);
  logMessage += `Unterschlagung FOT Geräte factor (${unterschlagungFOTGerateFactor}): ${result}\n`;

  result *= (1 + Number(vertragsbezogeneZuschlageItems[0].Regressverzicht) / 100);
  logMessage += `Regressverzicht (${vertragsbezogeneZuschlageItems[0].Regressverzicht}%): ${result}\n`;

  result *= Number(mitversicherungInnereUnruhenFactor);
  logMessage += `Mitversicherung innere Unruhen factor (${mitversicherungInnereUnruhenFactor}): ${result}\n`;

  vertragsbezogeneZuschlageItems[0].additionalParams.forEach((element: any, index) => {
    result *= (1 + Number(element.value) / 100);
    logMessage += `Vertragsbezogene Zuschläge ${element.name} (${element.value}%): ${result}\n`;
  });

  logMessage += `Final result: ${result}`;
  console.log(logMessage);

  return result;
}



function getSpindelklauselFactor(Spindelklausel): number {
  if (Spindelklausel) {
    return 0.8;
  }
  return 1;
}
function getVermietungUndVerpachtungFactor(vermietungUndVerpachtung): number {
  if (vermietungUndVerpachtung === 'Verleih an Mieter ohne geschultes') {
    return 1.25;
  }
  return 1;
}
function getVertragsartFactor(vertragsart, GAPDeckung): number {
  if (vertragsart === MACHINE_FAHRBAR && GAPDeckung) {
    return 1.05;
  }
  return 1;
}
function getUnterschlagungFOTGerateFactor(UnterschlagungFOTGerate): number {
  if (UnterschlagungFOTGerate) {
    return 1.05;
  }
  return 1;
}
function getMitversicherungInnereUnruhen(MitversicherungInnereUnruhen): number {
  if (MitversicherungInnereUnruhen) {
    return 1.03;
  }
  return 1;
}
function checkIfPriceDataHaveResponse(priceData, key: string): boolean {
  if (typeof priceData[key].priceResponse !== 'object' || Object.keys(priceData[key].priceResponse).length === 0) {
    return false;
  }
  return true;
}
function fillCalcPriceResponse(positionsdatenItems, newVal, beitragIndex, beitragFactorIndex, vertragsart, vertragsbezogeneZuschlageItem) {
  if (typeof newVal.priceResponse !== 'object' || Object.keys(newVal.priceResponse).length === 0) {
    return false;
  }
  positionsdatenItems.forEach((element: any, index) => {
    element.Pramiensatz = newVal.priceResponse.Result.Maschinen[index]['Faktor_Prämiensatz'];
    element.VSU = Number((Number(element.Versicherunswert) / Number(newVal.priceResponse.Result.Maschinen[index].Summenfaktor)).toFixed(0));
    element.Faktor = newVal.priceResponse.Result.Maschinen[index]['Prämienfaktor'];
    console.log(`positionsdatenItem   ${index + 1}` );
    element[beitragIndex] = getPramiePositionsdatenItem(newVal.priceResponse.Result.Maschinen[index]['Prämie_SB_Var1_Maschine'], element, vertragsart, vertragsbezogeneZuschlageItem);
    // element[beitragIndex] = newVal.priceResponse.Result.Maschinen[index]['Prämie_SB_Var1_Maschine'];
    element[beitragFactorIndex] = newVal.priceResponse.Result.Maschinen[index].SB_Rabatt_Faktor_1;
  });
}
function getSum371(positionsdatenItems) {
  let summ = 0;
  positionsdatenItems.forEach((element: any, index) => {
    summ += Number(element.VSU);
  });
  return summ
}
function getSumVersicherunswert(positionsdatenItems) {
  let summ = 0;
  positionsdatenItems.forEach((element: any, index) => {
    summ += Number(element.Versicherunswert);
  });
  return summ
}
export {
  fillErgebnisseTarifkalkulationPramie,
  fillCalcPriceResponse,
  calculatePramienSelbstbehaltsvariantenItems,
  getVersicherungssummeAktuell,
  getSum371,
  getSumVersicherunswert,
};
